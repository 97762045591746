import ContactForm from "../Components/ContactForm/ContactForm";
import Styles from "./Contact.module.css";

const Contact = () => {
	return (
		<div className={Styles.wrapper}>
			<div className={Styles.formContainer}>
				<h1>Want to grab a coffee and chat about art?</h1>
				<h3>Or anything else</h3>
				<ContactForm />
			</div>
		</div>
	);
};

export default Contact;

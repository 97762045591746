import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import Styles from "./Modal.module.css";

const Modal = ({ show, close, children }) => {
	return (
		<>
			{show ? (
				<div onClick={close} className={Styles.wrapper}>
					<div className={Styles.container}>
						<FontAwesomeIcon
							icon={faTimes}
							size="lg"
							onClick={close}
							className={Styles.closeBtn}
						/>
						{children}
					</div>
				</div>
			) : null}
		</>
	);
};

export default Modal;

import styles from "./Button.module.css";

function Button({ label, type, align, justify, margin, onClick }) {
	const btnStyles = {
		alignSelf: `${align}`,
		justifySelf: `${justify}`,
		margin: `${margin}`,
	};

	return (
		<button
			type={type}
			onClick={onClick}
			className={styles.button}
			style={btnStyles}
		>
			<span className={styles.buttonLabel}>{label}</span>
		</button>
	);
}

export default Button;

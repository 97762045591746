import { useEffect, useState } from "react";
import Styles from "./Home.module.css";
// import { ParallaxProvider, Parallax } from "react-scroll-parallax";

function Home({ prismicClient }) {
	const [document, setDocument] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setLoading(true);

		prismicClient
			.getSingle("home")
			.then((document) => {
				setDocument(document.data);
				setLoading(false);
			})
			.catch((error) => {
				// For now, assume error means a page could not be found for this URL.
				setLoading(false);
			});
	}, [prismicClient]);

	return (
		<div className={Styles.header}>
			{loading ? (
				<h1>Loading</h1>
			) : (
				<div
					className={Styles.headerImg}
					style={{
						backgroundImage: `url(${document.background_image.url})
					, linear-gradient(0deg, #000, #231f20)`,
					}}
				>
					<h1 className={Styles.heading}>
						{document.heading}
						<br /> {document.heading_second_line}
					</h1>
					<h4 className={Styles.subhead}>{document.subheading}</h4>
				</div>
			)}
		</div>
	);
}

export default Home;

import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect,
} from "react-router-dom";
import Prismic from "prismic-javascript";

import prismicConfig from "./prismic";
import "./App.css";
import Navbar from "./Components/Navbar/Navbar";
import Home from "./Pages/Home";
import Tiles from "./Pages/Tiles";
import About from "./Pages/About";
import Contact from "./Pages/Contact";

function App() {
	const prismicClient = Prismic.client(prismicConfig.api_url, {
		accessToken: prismicConfig.access_token,
	});

	return (
		<div className="App">
			<Router>
				<Navbar />
				<Switch>
					<Redirect exact from="/" to="/home" />
					<Route
						exact
						path="/home"
						render={() => <Home prismicClient={prismicClient} />}
					/>
					<Route
						exact
						path="/tiles"
						render={() => <Tiles prismicClient={prismicClient} />}
					/>
					<Route
						exact
						path="/about"
						render={() => <About prismicClient={prismicClient} />}
					/>
					<Route
						exact
						path="/contact"
						render={(props) => <Contact {...props} />}
					/>
				</Switch>
			</Router>
		</div>
	);
}

export default App;

import { useEffect, useState } from "react";
import Styles from "./Tiles.module.css";
import Modal from "../Components/Modal/Modal";

const Tiles = ({ prismicClient }) => {
	const [document, setDocument] = useState(null);
	const [loading, setLoading] = useState(true);
	const [modalState, setModalState] = useState(false);
	const [modalChild, setModalChild] = useState();

	useEffect(() => {
		setLoading(true);

		prismicClient
			.getSingle("tiles")
			.then((document) => {
				setDocument(document);
				setLoading(false);
			})
			.catch((error) => {
				// For now, assume error means a page could not be found for this URL.
				setLoading(false);
			});
	}, [prismicClient]);

	const imageModal = (url, title) => {
		setModalChild(
			<div className={Styles.modalChild}>
				<img src={url} alt={title} />
				<h5>{title}</h5>
			</div>
		);
		setModalState(!modalState);
	};

	return (
		<>
			<Modal
				children={modalChild}
				show={modalState}
				close={() => setModalState(false)}
			/>
			<div className={Styles.wrapper}>
				{loading ? (
					<h1>Loading</h1>
				) : (
					<div className={Styles.cardContainer}>
						{document.data.tile_grid.map(
							({ title, description, image: { url } }, index) => (
								<div
									className={Styles.tileCard}
									key={index}
									onClick={() => imageModal(url, title)}
								>
									<img src={url} alt={title} className={Styles.cardImage} />
									<div className={Styles.cardInfo}>
										<h5>{title}</h5>
									</div>
								</div>
							)
						)}
					</div>
				)}
			</div>
		</>
	);
};

export default Tiles;

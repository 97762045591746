import { useEffect, useState } from "react";
import { RichText } from "prismic-reactjs";

import Styles from "./About.module.css";

const About = ({ prismicClient }) => {
	const [document, setDocument] = useState(null);
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		setLoading(true);

		prismicClient
			.getSingle("about")
			.then((document) => {
				setDocument(document);
				setLoading(false);
			})
			.catch((error) => {
				// For now, assume error means a page could not be found for this URL.
				setLoading(false);
			});
	}, [prismicClient]);
	return (
		<div className={Styles.wrapper}>
			<div className={Styles.container}>
				{loading ? (
					<h1>Loading</h1>
				) : (
					document.data.section?.map((section, index) => (
						<div className={Styles.section} key={index}>
							<div className={Styles.sectionImageContainer}>
								<img
									src={section.image.url}
									alt={section.image.alt}
									className={Styles.sectionImage}
								/>
							</div>
							<div className={Styles.sectionInfo}>
								<h3>{section.heading}</h3>
								<RichText render={section.content} />
							</div>
						</div>
					))
				)}
			</div>
		</div>
	);
};

export default About;

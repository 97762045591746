import Button from "../../Molecules/Button/Button";
import styles from "./ContactForm.module.css";

function ContactForm() {
	return (
		<>
			<form className={styles.contactForm} name="contact" method="post">
				<input type="hidden" name="form-name" value="contact" />
				<input type="text" name="name" placeholder="Name" required />
				<input type="email" name="email" placeholder="Email" required />
				<input type="tel" name="phone" placeholder="Phone Number" />
				<textarea
					name="message"
					placeholder="Type your message here"
					rows="1"
					required
				></textarea>

				<Button type="submit" label="Submit" margin="15px" align="flex-end" />
			</form>
		</>
	);
}

export default ContactForm;

import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

import Styles from "./Navbar.module.css";

import Logo from "../../Assets/cusano_logo.png";

const getWidth = () => window.innerWidth <= 768;
const Navbar = () => {
	const [mobileDevice, setMobileDevice] = useState(getWidth);
	const [mobileMenuState, setMobileMenuState] = useState(false);

	const mobileNavStyles = {
		transform: `${mobileMenuState ? "" : "translateX(100%)"}`,
	};

	useEffect(() => {
		let timeoutId = null;
		const resizeListener = () => {
			clearTimeout(timeoutId);
			timeoutId = setTimeout(() => setMobileDevice(getWidth()), 150);
		};
		window.addEventListener("resize", resizeListener);

		return () => {
			window.removeEventListener("resize", resizeListener);
		};
	}, []);

	return (
		<div className={Styles.navContainer}>
			<div className={Styles.logoContainer}>
				<NavLink to="/home">
					<img src={Logo} alt="" height="80px" />
				</NavLink>
			</div>
			<FontAwesomeIcon
				onClick={() => setMobileMenuState(true)}
				icon={faBars}
				size="lg"
				className={Styles.menuOpenBtn}
			/>
			<div
				className={Styles.navbar}
				style={mobileDevice ? mobileNavStyles : null}
			>
				<FontAwesomeIcon
					icon={faTimes}
					size="lg"
					className={Styles.menuCloseBtn}
					onClick={() => setMobileMenuState(!mobileMenuState)}
				/>
				<NavLink
					className={Styles.link}
					activeClassName={Styles.activeLink}
					onClick={() => setMobileMenuState(!mobileMenuState)}
					to="/home"
				>
					Home
				</NavLink>
				<NavLink
					className={Styles.link}
					activeClassName={Styles.activeLink}
					onClick={() => setMobileMenuState(!mobileMenuState)}
					to="/tiles"
				>
					Tiles
				</NavLink>
				<NavLink
					className={Styles.link}
					activeClassName={Styles.activeLink}
					onClick={() => setMobileMenuState(!mobileMenuState)}
					to="/about"
				>
					About
				</NavLink>
				<NavLink
					className={Styles.link}
					activeClassName={Styles.activeLink}
					onClick={() => setMobileMenuState(!mobileMenuState)}
					to="/contact"
				>
					Contact
				</NavLink>
			</div>
		</div>
	);
};

export default Navbar;
